import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';

const ExiliumGuidesCombat: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page exilium-page'} game="exilium">
      <ul className="breadcrumb">
        <li>
          <Link to="/gfl-exilium/">Girls' Frontline 2: Exilium</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/gfl-exilium/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Combat Guide</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/exilium/categories/category_combat.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Combat Guide</h1>
          <h2>
            Basic information about Combat in Girls' Frontline 2: Exilium gacha
            game.
          </h2>
          <p>
            Last updated: <strong>19/11/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Differences between traditional XCOM-Lite and GFL2: Exilium" />
        <p>
          Girls’ Frontline 2 Exilium features a turn-based tactical combat
          system, commonly known as “XCOM-Lite”.
        </p>
        <StaticImage
          src="../../../images/exilium/generic/combat_1.webp"
          alt="Guides"
        />
        <p>
          Unlike traditional XCOM-Lite games, Girls’ Frontline 2: Exilium does
          not feature the traditional “hit chance" system, meaning all attacks
          are guaranteed to hit.
        </p>
        <StaticImage
          src="../../../images/exilium/generic/combat_2.webp"
          alt="Guides"
        />
        <p>
          <strong>Cover</strong> mechanics also differ from traditional
          XCOM-Lites as it does not provide evasion bonuses and/or Critical
          Strike reduction bonuses due to the hit chance system not being
          present in Girls’ Frontline 2: Exilium. Instead, it provides a flat
          30% Damage Reduction to units next to either{' '}
          <strong>Half-Body Cover</strong> or <strong>Full-Body Cover</strong>.
          Half-Body Cover allows units to jump over obstacles and provides a
          full range of attacks to occur while Full-Body Cover blocks
          Line-of-Sight and only allows a partial range of attacks to occur.
        </p>
        <StaticImage
          src="../../../images/exilium/generic/combat_3.webp"
          alt="Guides"
        />
        <p>
          Units next to Full-Body Cover when attacking will conduct a{' '}
          <strong>“Leaning Attack”</strong>. Leaning Attack cannot occur if the
          tile adjacent to the unit is blocked or occupied.
        </p>
        <StaticImage
          src="../../../images/exilium/generic/combat_4.webp"
          alt="Guides"
        />
        <p>
          There are also <strong>Special Covers</strong> present in the game,
          called <strong>Iron Fence</strong> and <strong>Energy Cover</strong>.
          Iron Fence can block movement and melee attacks from occurring but
          cannot block ranged attacks.Energy Cover on the other hand can block
          ranged attacks but not movement and melee attacks.
        </p>
        <StaticImage
          src="../../../images/exilium/generic/combat_5.webp"
          alt="Guides"
        />
        <p>
          In addition to <strong>Permanent Covers</strong>, there are also{' '}
          <strong>Destructible Covers</strong> that can be destroyed to remove
          Cover protection from units by utilizing skills that have Cover
          Destruction.
        </p>
        <StaticImage
          src="../../../images/exilium/generic/combat_6.webp"
          alt="Guides"
        />
        <p>
          The <strong>Cover system</strong> has also been changed to include a{' '}
          <strong>Stability Index</strong> system. Stability Index provides a
          60% damage reduction when units are next to Cover, on top of the 30%
          Damage Reduction provided by the Cover. Incoming attacks reduce the
          Stability Index (indicated by a shield icon) until the number reaches
          0, placing the unit in <strong>Stability Collapse</strong>.
        </p>
        <StaticImage
          src="../../../images/exilium/generic/combat_7.webp"
          alt="Guides"
        />
        <p>
          A unit in <strong>Stability Collapse</strong> will not receive the
          Damage Reduction from Stability Index. T-Dolls and enemies can recover
          Stability Index via certain skills. Stability Index does not provide
          its Damage Reduction when the shield icon has reached 0, if the unit
          is not next to Cover, or if the unit has been flanked by an opposing
          unit.
        </p>
        <StaticImage
          src="../../../images/exilium/generic/combat_8.webp"
          alt="Guides"
        />
        <p>
          It is important to note that the Stability Index system does not apply
          to Melee units, who can ignore the Damage Reduction against units next
          to Cover. The 30% Damage Reduction from having Cover will still apply
          to the damage.
        </p>
        <StaticImage
          src="../../../images/exilium/generic/combat_9.webp"
          alt="Guides"
        />
        <p>
          The Stability Index system also does not apply to AoE attacks, and
          instead only Cover provides Damage Reduction depending on the location
          of the AoE attacks. Some AoE attacks can also ignore Cover protection,
          allowing them to deal full damage.
        </p>
        <StaticImage
          src="../../../images/exilium/generic/combat_10.webp"
          alt="Guides"
        />
        <p>
          Attacks from <strong>High Ground</strong>, unlike traditional
          XCOM-Lites, can also ignore <strong>Stability Index</strong> and{' '}
          <strong>Cover</strong>, allowing the attacking unit to deal full
          damage. However, if they are not on{' '}
          <strong>Perforated High Ground</strong>, they cannot target units
          directly below them.
        </p>
        <StaticImage
          src="../../../images/exilium/generic/combat_11.webp"
          alt="Guides"
        />
        <p>
          Another difference from traditional XCOM-Lite games is the removal of
          the <strong>Overwatch</strong> function from all units. Instead,
          Girls’ Frontline 2: Exilium introduces a similar mechanic called{' '}
          <strong>“Action Support”</strong> which is a unit buff that allows the
          unit to assist friendly units in attacks or counterattack opposing
          units when a friendly unit takes damage. Action Support is limited to
          the unit’s range and will not occur if the unit is out of range.
        </p>
        <StaticImage
          src="../../../images/exilium/generic/combat_12.webp"
          alt="Guides"
        />
        <p>
          In addition to Action Support, enemy units may have active or passive
          skills that apply <strong>“Surveillance”</strong>. Surveillance has
          two types - <strong>Movement</strong> and <strong>Action</strong>,
          indicated by red icons on the ground.{' '}
          <strong>Movement Surveillance</strong> will trigger an attack from the
          enemy if the controllable unit performs a movement in the Surveilled
          area. Similarly, <strong>Action Surveillance</strong> will trigger an
          attack from the enemy if the controllable unit performs an action.
        </p>
        <SectionHeader
          title="Girls’ Frontline 2: Exilium Combat Experience
"
        />
        <StaticImage
          src="../../../images/exilium/generic/combat_13.webp"
          alt="Guides"
        />
        <p>
          Beside the differences listed here, Girls’ Frontline 2: Exilium plays
          similarly to traditional XCOM-Lites with the addition of RPG-like
          mechanics.
        </p>
        <p>
          Each Combat Sequence is divided into turns. Each turn consists of a{' '}
          <strong>Movement Phase</strong> and <strong>Action Phase</strong>.
          During the Movement Phase, units may move a certain amount of tiles
          depending on their <strong>Action Points</strong>.
        </p>
        <StaticImage
          src="../../../images/exilium/generic/combat_14.webp"
          alt="Guides"
        />
        <p>
          All T-Dolls have their own Action Points that determine how many tiles
          they can move in any direction every Movement Phase.
        </p>
        <StaticImage
          src="../../../images/exilium/generic/combat_15.webp"
          alt="Guides"
        />
        <p>
          While movement can only be done once per turn, certain skills can
          grant an <strong>“Additional Movement”</strong> buff, which allows
          movement once more at the cost of not being able to conduct any other
          action.
        </p>
        <StaticImage
          src="../../../images/exilium/generic/combat_16.webp"
          alt="Guides"
        />
        <p>
          During the Action Phase, combat is conducted by a T-Doll utilizing
          various skills to deal damage. Every Skill comes with its own range
          and Area of Effect (AoE), as well as its own Stability Damage dealt.
        </p>
        <StaticImage
          src="../../../images/exilium/generic/combat_17.webp"
          alt="Guides"
        />
        <p>
          As Girls’ Frontline 2: Exilium is skill-based combat rather than the
          traditional XCOM-Lites’ weapon-based combat, T-Dolls don’t need to
          reload their weapons at all. As such, ammunition and reload mechanics
          are not featured in Girls’ Frontline 2: Exlilium.
        </p>
        <StaticImage
          src="../../../images/exilium/generic/combat_18.webp"
          alt="Guides"
        />
        <p>
          Additionally, each T-Doll has a corresponding{' '}
          <strong>Phase Element</strong> and each weapon they wield has a{' '}
          <strong>Weapon Damage Attribute.</strong> There are 5 Phase Elements:{' '}
          <strong>Burn, Electric, Hydro, Freeze, and Corrosion.</strong> Weapon
          Damage Attributes are also divided into 5 categories:{' '}
          <strong>
            Light Ammo, Medium Ammo, Heavy Ammo, Melee and Shotgun Ammo.
          </strong>{' '}
          Both T-Dolls and enemies have Weapon Damage Attribute weakness and
          Phase Element weakness. When exploiting Phase Attributes and/or Weapon
          Damage Attributes, the damage is increased by 10% and an extra 2 fixed
          Stability Damage is dealt. Stability Damage will determine the final
          outcome of the total damage, as Stability Damage Reduction may be
          negated if the unit enters Stability Collapse.
        </p>
        <StaticImage
          src="../../../images/exilium/generic/combat_19.webp"
          alt="Guides"
        />
        <p>
          Aside from basic skills, T-Dolls have an “Ultimate Skill” using the{' '}
          <strong>“Confectance Index”</strong>. Confectance Index will be shown
          above the unit’s blue HP bar and will increase based on damage dealt
          and effects from unit skills.
        </p>
        <StaticImage
          src="../../../images/exilium/generic/combat_20.webp"
          alt="Guides"
        />
        <p>
          Similar to Movement Phase, Action Phase can also be re-initiated
          through an <strong>“Additional Action”</strong> buff granted by
          skills. Additional Action will provide another action to be conducted,
          but prohibits any movement.
        </p>
        <StaticImage
          src="../../../images/exilium/generic/combat_20.webp"
          alt="Guides"
        />
        <p>
          At any point during your turn phase, if the actions you performed last
          turn or this current turn do not suit you, or if you made a mistake,
          you may use the <strong>“Rewind”</strong> feature. Rewind allows you
          to turn back to a previous turn phase up to 3 times per battle. You
          may also use the Rewind feature when you have a lost battle by
          clicking on “Undo”.
        </p>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default ExiliumGuidesCombat;

export const Head: React.FC = () => (
  <Seo
    title="Combat Guide | Girls' Frontline 2: Exilium | Prydwen Institute"
    description="Basic information about Combat in Girls' Frontline 2: Exilium gacha game."
    game="exilium"
  />
);
